import { template as template_5dea3c59db1448319c2f3cb86cfe90d6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_5dea3c59db1448319c2f3cb86cfe90d6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
