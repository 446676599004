import { template as template_5c5be763d8da4c97a80b2a32e72b2d8a } from "@ember/template-compiler";
const FKLabel = template_5c5be763d8da4c97a80b2a32e72b2d8a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
