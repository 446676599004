import { template as template_497f7eb4eac74002b4156e601f8e9470 } from "@ember/template-compiler";
const FKText = template_497f7eb4eac74002b4156e601f8e9470(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
