import { template as template_f3040047f0134f6284a2f2e27d676684 } from "@ember/template-compiler";
const WelcomeHeader = template_f3040047f0134f6284a2f2e27d676684(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
