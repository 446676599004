import { template as template_aea93011de7c4bc3a53826d016f005e7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aea93011de7c4bc3a53826d016f005e7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
